import React from "react";
import Footer from "../Home/Footer";
import About from "../../assets/aboutus.jpg";
import Header from "../Home/Header";

function AboutUs({ setLoginShow }) {
  return (
    <div>
      <Header setLoginShow={setLoginShow} />
      <div className="container my-5">
        <h2 className="text-center fw-bold">About Us</h2>
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "60px", marginBottom: "60px" }}
        >
          <div style={{ width: "90%" }}>
            <div className="row">
              <div
                className="col-lg-6 order-2 order-lg-1 d-flex align-items-center mb-5"
                style={{ lineHeight: "2", textAlign: "justify" }}
              >
                <div>
                  <p>
                    An Ayurveda, yoga & lifestyle, herbs and home remedies
                    website to disseminate authentic, science backed, ancient
                    healthy living wisdom to the world. Our portal also promotes
                    products and services related to Ayurveda, Yoga and
                    Naturopathy.
                  </p>
                  <p className="mt-5">
                    We help the manufacturers of health-related products to
                    reach the consumers through our portal. While "health of
                    people" is our primary focus, but we are also inclusive
                    about making small & medium enterprises to prosper through
                    MeruWell's portal.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 d-flex align-items-center mb-5">
                <img src={About} alt="About us vector" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUs;
