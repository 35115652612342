/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { cartContoller } from "../../../api/cart";
import { showNotification } from "../../../utils/showNotification";
import SyncLoader from "../../Loader/SyncLoader";

function InstamojoPayment() {
  const history = useNavigate();
  const [params] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");

  const getPaymentStatus = async () => {
    try {
      setLoading(true);
      const res = await cartContoller.getInstamojoPaymentStatus({
        request_id: params.get("payment_request_id"),
        payment_id: params.get("payment_id"),
      });
      if (!res.success) {
        showNotification(res.message, "error");
        setLoading(false);
      } else {
        setStatus(res.data.status);
        setLoading(false);
      }
      localStorage.removeItem("payment_id");
      localStorage.removeItem("request_id");
    } catch (err) {
      showNotification(err.message, "error");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.get("payment_id")) {
      localStorage.setItem("payment_id", params.get("payment_id"));
      localStorage.setItem("request_id", params.get("payment_request_id"));
      history("/payment-redirect");
      getPaymentStatus();
    } else {
      history("/404");
    }
  }, []);

  return (
    <div className="min-vh-100 d-flex justify-content-center align-items-center">
      {loading && (
        <div>
          Please wait while we fetch your payment status
          <SyncLoader />
        </div>
      )}
      {!loading && status !== "captured" && (
        <div className="text-center">
          <i
            className="bi bi-x-circle-fill"
            style={{ fontSize: "45px", color: "red" }}
          ></i>

          <>
            <h3 className="my-4">
              {status === "failed"
                ? "Your payment has been Cancelled"
                : "Oh no! Something went wrong."}
            </h3>
            <h6 className="my-4 text-danger">
              {status !== "failed" &&
                "We aren't able to process your Payment. Please Try Again."}
            </h6>
          </>

          <button
            type="button"
            className="btn btn-primary"
            onClick={() => history("/cart", { replace: true })}
          >
            <i className="bi bi-arrow-left me-2"></i>Back to Cart
          </button>
        </div>
      )}
      {!loading && status === "captured" && (
        <div className="text-center">
          <i
            className="bi bi-check-circle-fill"
            style={{ fontSize: "45px", color: "green" }}
          ></i>
          <h3 className="my-4">Payment Successful!</h3>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => history("/cart", { replace: true })}
          >
            <i className="bi bi-arrow-left me-2"></i>Back to Cart
          </button>
        </div>
      )}
    </div>
  );
}

export default InstamojoPayment;
