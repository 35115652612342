import React, { useState } from "react";
import "./AdminLogin.css";
import logo from "../../../assets/meruwell-logo.webp";
import { useNavigate } from "react-router-dom";
import bcrypt from "bcryptjs";
import { adminLoginController } from "../../../api/admin/login.js";
import { showNotification } from "../../../utils/showNotification";
import Loader from "../../Loader/Loader";
import { useFormik } from "formik";
import * as yup from "yup";

function AdminLogin() {
  const history = useNavigate();
  const [loading, setLoading] = useState(false);

  const initialValue = {
    userName: "",
    password: "",
  };

  const schema = yup.object({
    userName: yup.string().trim().required("*Please fill in user name"),
    password: yup.string().trim().required("*Please fill in password"),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const salt = await bcrypt.genSalt(6);
        const hashedPassword = await bcrypt.hash(values.password, salt);

        const login = await adminLoginController.signIn({
          userName: values.userName,
          password: hashedPassword,
        });

        if (login.success) {
          localStorage.setItem("meruwell_token", login.data.token);
          showNotification("Signed In Successfully", "success");
          history("/admin");
        } else {
          showNotification(login.message, "error");
        }
      } catch (err) {
        showNotification(err.message, "error");
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="background_container">
          <div className="login_container">
            <div className="logo_container">
              <img
                src={logo}
                alt="logo"
                width={55}
                height={55}
                style={{ marginTop: "4%" }}
              />
            </div>
            <h2 className="text-center">Admin Login</h2>
            <div className="form-container">
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="User Name"
                    value={formik.values.userName}
                    name="userName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.touched.userName && formik.errors.userName ? (
                    <p className="mt-1 mb-0 text-warning">
                      {formik.errors.userName}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    value={formik.values.password}
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <p className="mt-1 mb-0 text-warning">
                      {formik.errors.password}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="w-100 text-center">
                  <button
                    type="submit"
                    className="btn btn-outline-light mt-5 w-75"
                  >
                    Sign In
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AdminLogin;
