import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import "./Dropdown.css";

function Dropdown({ menuName, menuList }) {
  const [show, setShow] = useState(false);
  return (
    <div className="dropdown-container">
      <div
        onClick={() => setShow(!show)}
        className="d-flex justify-content-between"
      >
        {menuName}{" "}
        <i className={`bi ${show ? "bi-chevron-up" : "bi-chevron-down"}`}></i>
      </div>
      <div className="mt-2" hidden={!show}>
        {menuList?.map((menu, index) => {
          return (
            <Nav.Item key={menu.name + index} style={{ cursor: "pointer" }}>
              <Nav.Link className="link" eventKey={menu.key}>
                {menu.name}
              </Nav.Link>
            </Nav.Item>
          );
        })}
      </div>
    </div>
  );
}

export default Dropdown;
