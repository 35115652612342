/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { showNotification } from "../../../../utils/showNotification";
import Loader from "../../../Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { ordersController } from "../../../../api/admin/orders";
import DataTable from "../../../Common/DataTable/DataTable";

function ListOrders() {
  const history = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState({
    columns: [
      {
        label: "Order Id",
        field: "order_id",
      },
      {
        label: "Ordered On",
        field: "created_at",
      },
      {
        label: "Status",
        field: "order_status",
      },
      {
        label: "View PDF",
        field: "action",
      },
    ],
    rows: [],
  });

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const result = await ordersController.getAllOrders({
        page: query.get("page") || 1,
        limit: query.get("limit") || 10,
      });
      if (result.success) {
        setOrders({
          ...orders,
          totalCount: result.orderCount,
          rows: result.ordersList.map((order) => {
            return {
              ...order,
              created_at: new Date(order.created_at).toLocaleDateString(
                "en-GB"
              ),
              action: (
                <>
                  <span
                    className="me-3"
                    onClick={() => history(`/admin/orders/${order.id}`)}
                  >
                    <i className="icons bi bi-file-earmark-pdf-fill"></i>
                  </span>
                </>
              ),
            };
          }),
        });
      }
    } catch (error) {
      showNotification(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [location]);

  return (
    <div className="container mt-5">
      {loading && <Loader />}
      <h2>ORDERS LIST</h2>
      <div className="mt-4">
        {!loading && (
          <DataTable
            data={orders}
            limitOptions={[10, 20, 40, 50]}
            noRecordsFoundLabel={"No Products Found"}
            sortable={false}
          />
        )}
      </div>
    </div>
  );
}

export default ListOrders;
