import React from "react";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import classes from "./Policies.module.css";

function ShippingPolicy() {
  return (
    <div>
      <Header />
      <div className="container my-5">
        <h2 className="text-center fw-bold">Shipping Policy</h2>
        <div className={"lh-lg " + classes.policyContainer}>
          <div className={classes.wrapper}>
            <div>
              <p>
                Before proceeding to pay for your order, your pin code along
                with the shipping address must be verified. If for some reason
                we are not able to verify this shipping address, you will be
                required to offer us an alternate address. This alternate
                address must comply with our delivery partners. The address must
                correlate with the delivery partner's list. These shipping
                policies of MeruWell are in place for a reason. Shipping and
                delivery policies of MeruWell are put into place to ensure all
                products are delivered to the correct address. We sync this
                address with our delivery partner.
              </p>
              <p>
                In the case of a mistake or a loss, MeruWell is not responsible
                for products that are not delivered. We do not handle
                International deliveries. If you want an international delivery
                you will have to check out our website which is{" "}
                <a href="https://meruwell.com">www.meruwell.com</a>. We do
                accept international orders on our website. Simply, make sure
                you provide us with the correct address for where you want your
                products delivered.
              </p>
              <p>
                If you choose to have an order with multiple services and
                products, we will attempt to ship all of your products together
                at once. This will not always be the case though. Certain
                logistics issues sometimes arise where all products cannot be
                shipped at the same exact time. If you want your products to be
                delivered to different addresses, then please place separate
                orders. On each separate order fill out the correct address of
                where you want these products to be delivered to.
              </p>
              <p>
                At MeruWell, we take pride in our packaging. Products will be
                packaged to protect them while they are in transit to you.
                Packaging protects the products and ensures there will be no
                damage caused. There will be possible shipping charges. There
                will be shipping costs for certain products. Shipping costs are
                different for international orders. Shipping costs will vary
                from country to country. If a price is in INR, then this is for
                India only. Delivery of products takes between 7 and 10 days.
                International orders are going to take 31 days. We ship out
                orders from our warehouses. Orders will be shipped from the
                warehouse within 24hrs - 48hrs of the order being placed.
              </p>
              <p>
                Our delivery is done through a select few companies. These
                companies include Professional Courier, Bluedart, DTDC, and DHL
                Fedex. If a courier route is not available, then we will work
                with you to find an alternate route to ensure you receive your
                MeruWell ayurvedic products. The company that does the delivery
                will offer a tracking number to customers. In addition, you can
                log into your account and you will be able to find the tracking
                number.
              </p>
              <p>
                Please do prepare for taxes.There are local taxes for countries
                that exist outside of India. Customs taxes and other
                international fees and taxes may apply. These taxes will have to
                be supported by the customer. For any concerns or questions
                about your products, please reach out to us. Our customer
                service number is +919442406780. We can be contacted from Monday
                to Saturday during 10 AM and 6 PM.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ShippingPolicy;
