/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { adminProductController } from "../../../../api/admin/products";
import { showNotification } from "../../../../utils/showNotification";
import Loader from "../../../Loader/Loader";
import AddProduct from "../AddProduct/AddProduct";

function EditProduct() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState();

  const getProduct = async () => {
    try {
      setLoading(true);
      const res = await adminProductController.getProductById(id);
      if (res.success) {
        setEditData(res.data);
      } else {
        showNotification(res.message, "error");
      }
    } catch (err) {
      showNotification(err.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProduct();
  }, [id]);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <AddProduct mode={"edit"} data={editData} refresh={getProduct} />
      )}
    </>
  );
}

export default EditProduct;
