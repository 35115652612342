export const optionsFormatter = (options) => {
  return options.map((option) => ({
    value: option,
    label: option,
  }));
};

export const getPageNos = (total, limit) => {
  if (!total) {
    return [];
  }
  const pages = Math.ceil(total / limit);
  return [...Array(pages).keys()];
};
