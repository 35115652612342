/*eslint-disable react-hooks/exhaustive-deps*/
/*eslint-disable react-hooks/rules-of-hooks*/

import React, { useEffect, useState } from "react";
import { cartContoller } from "../../api/cart";
import { showNotification } from "../../utils/showNotification";
import Loader from "../Loader/Loader";
import "./Cart.css";
import { Debounce } from "../../utils/debounce";
import { useNavigate } from "react-router-dom";
import baseURL from "../../utils/urls";

function Cart({ setLoginShow, refresh, setRefresh }) {
  const [cartItems, setCartItems] = useState([]);
  const [localRefresh, setLocalRefresh] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState();
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  let debouncedValue = Debounce(cartItems[currentIndex]?.quantity, 2000);
  const history = useNavigate();

  const deleteItem = async (id) => {
    try {
      setLoading(true);
      const result = await cartContoller.deleteCartItem(id);
      if (result.success) {
        setRefresh(!refresh);
      } else {
        showNotification(result.message, "error");
      }
    } catch (error) {
      showNotification(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async (e, ind) => {
    let qty = Number(e?.target?.value);
    setCartItems((prev) => {
      prev[ind].quantity = qty || "";
      return prev;
    });
    setLocalRefresh(!localRefresh);

    if (qty) {
      if (qty < 0) {
        showNotification("Quantity can't be negative", "error");
      } else {
        setCurrentIndex(ind);
      }
    } else {
      showNotification("Quantity can't be empty", "error");
    }
  };

  const updateQuantity = async () => {
    try {
      setLoading(true);
      const res = await cartContoller.updateCartQuantity({
        cart_id: cartItems[currentIndex].id,
        quantity: cartItems[currentIndex].quantity,
      });
      if (!res.success) {
        showNotification(res.message, "error");
      }
    } catch (err) {
      showNotification(err, "error");
    } finally {
      setLoading(false);
      setCurrentIndex(undefined);
    }
  };

  const fetchCartData = async () => {
    setCurrentIndex(undefined);
    if (localStorage.getItem("meruwell_token")) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
    try {
      setLoading(true);
      const result = await cartContoller.getCartItemsByUserId();
      let delcharges = 0;
      if (result.success) {
        let cart = result?.cartItems;
        cart = cart.map((item) => {
          item.product_image = item.product_image
            ? item.product_image.split(",")
            : [];
          item.price_with_tax =
            item.unit_price + (item.unit_price * item.gst) / 100;
          delcharges += item.delivery_charges;
          return item;
        });
        setCartItems(cart);
        setDeliveryCharges(delcharges);
      } else {
        showNotification(result.message, "error");
      }
    } catch (error) {
      showNotification(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCartData();
  }, [refresh]);

  useEffect(() => {}, [isLoggedIn]);

  useEffect(() => {
    if (debouncedValue) {
      updateQuantity();
    }
  }, [debouncedValue]);

  return (
    <div className="container cart-container">
      <h2>
        My Bag{" "}
        {cartItems.length
          ? `(${cartItems.length} ${cartItems.length === 1 ? "item" : "items"})`
          : ""}
      </h2>
      {loading && <Loader />}
      {!loading &&
        (cartItems.length ? (
          <div className="row mt-5">
            <div className="col-lg-9 mb-4">
              {cartItems.map((item, ind) => {
                return (
                  <div className="card mb-3" key={item.id}>
                    <div className="row g-0">
                      <div className="col-4 col-md-3 col-lg-3 col-xl-2">
                        <div className="position-relative">
                          <img
                            src={`${baseURL}/uploads/${item?.product_image[0]}`}
                            className="img-fluid rounded-start img-container"
                            alt={item?.product_name}
                          />
                          {item?.total_quantity - item?.sold_quantity <= 0 && (
                            <div className="d-flex justify-content-center">
                              <span className="unavailable badge">
                                <div className="mb-1">Currently</div>
                                <div>Unavailable</div>
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="d-block d-md-none m-2">
                          <div className="row">
                            <label
                              htmlFor="Qty"
                              className="col-4 col-form-label pe-0"
                            >
                              Qty
                            </label>
                            <div className="col-6 p-0">
                              <input
                                type="number"
                                className="form-control"
                                id="Qty"
                                min="1"
                                value={item?.quantity}
                                onChange={(e) => {
                                  handleChange(e, ind);
                                }}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-8 col-md-9 col-lg-9 col-xl-10">
                        <div className="card-body h-100 d-flex flex-column justify-content-between">
                          <div className="card-text row">
                            <p className="col-md-6 col-lg-7 col-xl-5">
                              {item.product_brand + " - " + item.product_name}
                            </p>
                            <div className="d-none d-md-block offset-md-1 offset-lg-1 offset-xl-0 col-md-5 col-lg-4 col-xl-2">
                              <div className="row">
                                <label
                                  htmlFor="Qty"
                                  className="offset-md-2 offset-lg-0 offset-xl-1 col-md-3 col-lg-5 col-form-label pe-0"
                                >
                                  Qty
                                </label>
                                <div className="col-6 p-0">
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="Qty"
                                    min="1"
                                    value={item?.quantity}
                                    onChange={(e) => {
                                      handleChange(e, ind);
                                    }}
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                            </div>
                            <p className="mt-2 offset-md-5 offset-lg-5 offset-xl-0 col-md-7 col-lg-7 col-xl-5 d-flex justify-content-end flex-column flex-md-row gap-2">
                              <>
                                {item.discount ? (
                                  <div className="d-flex justify-content-end">
                                    <s>₹. {item?.price_with_tax?.toFixed(2)}</s>
                                    &nbsp;(
                                    {item?.discount ? item.discount : 0}%)
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div className="d-flex justify-content-end">
                                  <span className="bg-info p-2 border-start border-5 border-primary">
                                    ₹&nbsp;
                                    {Math.round(
                                      item?.price_with_tax -
                                        (item?.price_with_tax *
                                          item?.discount) /
                                          100
                                    ).toFixed(2)}
                                  </span>
                                </div>
                              </>
                            </p>
                          </div>
                          <div className="d-flex justify-content-end">
                            <div>
                              <button
                                className="btn"
                                style={{ backgroundColor: "#AEAEAE" }}
                                onClick={() => deleteItem(item?.id)}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="offset-md-2 offset-lg-0 col-md-8 col-lg-3 mb-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Order Summary</h5>
                  <div className="mt-3">
                    <table className="table-style w-100">
                      <tbody>
                        <tr>
                          <td>Bag Total</td>
                          <td className="text-end">
                            ₹&nbsp;
                            {Math.round(
                              cartItems.reduce(
                                (acc, curr) =>
                                  acc + curr.quantity * curr.price_with_tax,
                                0
                              )
                            ).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td>Bag Discount</td>
                          <td className="text-end">
                            ₹&nbsp;
                            {cartItems
                              .reduce(
                                (acc, curr) =>
                                  acc +
                                  curr.quantity *
                                    (curr.price_with_tax *
                                      (curr.discount / 100)),
                                0
                              )
                              .toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td>Delivery</td>
                          <td className="text-end">
                            {deliveryCharges
                              ? "₹ " + deliveryCharges.toFixed(2)
                              : "FREE"}
                          </td>
                        </tr>
                        <tr>
                          <td>Order Total</td>
                          <td className="text-end fw-bold">
                            ₹&nbsp;
                            {cartItems
                              .reduce(
                                (acc, curr) =>
                                  acc +
                                  curr.quantity *
                                    Math.round(
                                      curr.price_with_tax -
                                        (curr.price_with_tax * curr.discount) /
                                          100
                                    ),
                                deliveryCharges
                              )
                              .toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button
                      className="mt-4 w-100 btn btn-warning"
                      onClick={() => {
                        if (isLoggedIn) {
                          history("/address", { state: "cart", replace: true });
                        } else {
                          showNotification(
                            "Please Login to Checkout!",
                            "warning"
                          );
                          setLoginShow(true);
                        }
                      }}
                      disabled={cartItems.some(
                        (item) => item.total_quantity <= item.sold_quantity
                      )}
                    >
                      PROCEED TO SHIPPING
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "50vh" }}
          >
            <div>
              <p className="text-center">
                <i
                  className="fas fa-shopping-bag fa-4x"
                  style={{ color: "rgba(0,0,0,0.2)" }}
                ></i>
              </p>
              <h3>Your Bag is Empty!</h3>
            </div>
          </div>
        ))}
    </div>
  );
}

export default Cart;
