import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./HomePage.css";
import axiom from "../../assets/axiom-logo.png";
import kapiva from "../../assets/kapiva-logo.png";
import khadi from "../../assets/khadi-natural-logo.png";
import mantra from "../../assets/mantra-logo.png";
import diabetes from "../../assets/diabetic.jpg";
import joinPain from "../../assets/joint-pain.jpg";
import stress from "../../assets/stress-management.jpg";
import stevia from "../../assets/stevia-plants.png";
import leafIcon from "../../assets/icons/green-leaf-icon.svg";
import Footer from "./Footer";
import Header from "./Header";
import baseURL from "../../utils/urls";
import { blogsController } from "../../api/blogs";
import { showNotification } from "../../utils/showNotification";

function HomePage({ setLoginShow, setPage, refresh, setRefresh }) {
  const history = useNavigate();
  const [blogsList, setBlogsList] = useState([]);

  const handlePlan = () => {
    const jwtToken = localStorage.getItem("meruwell_token");
    if (!jwtToken) {
      setLoginShow(true);
      setPage("plan");
    } else {
      history("/plan/details", { state: "plan", replace: true });
    }
  };

  const getAllBlogs = async () => {
    try {
      const res = await blogsController.getAllBlogs(3);
      if (res.success) {
        setBlogsList(res.blogsList);
      } else {
        showNotification(res.message);
      }
    } catch (error) {
      showNotification(error.message, "error");
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  return (
    <div>
      <Header
        setLoginShow={setLoginShow}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <div className="hero-container">
        <div className="container">
          <div className="header d-flex align-items-center">
            <div className="header-container">
              <h5>Holistic Lifestyle Products</h5>
              <h1>MERUWELL HEALTH</h1>
              <p className="my-5">
                MeruWell helps you on your cure by bringing the right products
                and services of Ayurveda, Yoga and Naturopathy, to your home.
              </p>
              <button
                className="btn rounded-pill shop-button text-white"
                style={{ backgroundColor: "var(--orange)" }}
                onClick={() => history("/products")}
              >
                SHOP NOW
              </button>
            </div>
          </div>
          <div className="background-container"></div>
        </div>
      </div>
      <div className="card-wrapper container">
        <div className="row">
          <div className="col-lg-4 col-class">
            <div className="col-class-div">
              <img className="img-fluid" alt="diabetes" src={diabetes} />
              <div className="px-2">
                <h3 className="mt-4 mb-3">Diabetes</h3>
                <p>
                  Our body wants to be healthy. Our body is a chemical soup -
                  either it is a great soup or a lousy soup. The soup becomes
                  lousy either because the ingredients that you are using are
                  not good, or the way you are cooking it is not good. Diabetes
                  is curable if one follows holistic approach on eating,
                  exercising and well managing the body. MeruWell promotes these
                  products to give you the best that you are looking for to
                  treat yourself. You can use these products to help yourself on
                  your diabetic cure. MeruWell's services is all about helping
                  you.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-class">
            <div className="col-class-div">
              <img
                className="img-fluid"
                alt="Stress Management"
                src={stress}
                style={{ width: "300px" }}
              />
              <div className="px-2">
                <h3 className="mt-4 mb-3">Stress Management</h3>
                <p>
                  Stress is not a part of your life. Stress is just your
                  inability to manage your own system. Stress happens not
                  because of the nature of your work. The first and foremost
                  thing we need to work at is to make a human being peaceful and
                  joyful. This will invariably happen if you do the right type
                  of yoga. If it is done properly - because yoga is a subjective
                  science - if it is delivered properly, it will work like a
                  miracle and make this happen. MeruWell can help you on your
                  joy! MeruWell promotes "stress free" life for all.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-class">
            <div className="col-class-div">
              <img className="img-fluid" alt="Joint Pain" src={joinPain} />
              <div className="px-2">
                <h3 className="mt-4 mb-3">Joint Pain</h3>
                <p>
                  MeruWell places an emphasis on classical Ayurvedic therapies
                  which provide relief from acute pain, tension and stiffness,
                  while nourishing muscles, rebuilding connective tissues,
                  improving blood circulation and strengthening the nervous
                  system. MeruWell can help you with integrated approach of
                  combining Ayurveda, Yoga and Naturopathy, to help you on
                  minimising your pains.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid subscription-container">
        <h1 className="subscription-header">
          <b>Premium Plans</b>
        </h1>
        <div className="d-flex justify-content-center">
          <div className="row plan-card-container">
            <div className="col-md-4">
              <div
                className="plan-card"
                style={{ backgroundColor: "var(--lightPurple)" }}
              >
                <div
                  className="price-container"
                  style={{ backgroundColor: "var(--purple)" }}
                >
                  <h6>Free</h6>
                  <h1>
                    <b>$0</b>
                  </h1>
                  <h6>PER MONTH</h6>
                </div>
                <div className="text-white text-center features-container">
                  <ul className="list-unstyled lh-lg">
                    <li>15 users</li>
                    <li>Feature 1</li>
                    <li>Feature 2</li>
                  </ul>
                </div>
                <div className="text-center">
                  <button
                    className="btn btn-light rounded-pill plan-button"
                    style={{ color: "var(--lightPurple)" }}
                    onClick={handlePlan}
                  >
                    GET PLAN
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="plan-card"
                style={{ backgroundColor: "var(--lightGreen)" }}
              >
                <div
                  className="price-container"
                  style={{ backgroundColor: "var(--green)" }}
                >
                  <h6>Free</h6>
                  <h1>
                    <b>$0</b>
                  </h1>
                  <h6>PER MONTH</h6>
                </div>
                <div className="text-white text-center features-container">
                  <ul className="list-unstyled lh-lg">
                    <li>15 users</li>
                    <li>Feature 1</li>
                    <li>Feature 2</li>
                  </ul>
                </div>
                <div className="text-center">
                  <button
                    className="btn btn-light rounded-pill plan-button"
                    style={{ color: "var(--lightGreen)" }}
                  >
                    GET PLAN
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="plan-card"
                style={{ backgroundColor: "var(--lightTangerine)" }}
              >
                <div
                  className="price-container"
                  style={{ backgroundColor: "var(--tangerine)" }}
                >
                  <h6>Free</h6>
                  <h1>
                    <b>$0</b>
                  </h1>
                  <h6>PER MONTH</h6>
                </div>
                <div className="text-white text-center features-container">
                  <ul className="list-unstyled lh-lg">
                    <li>15 users</li>
                    <li>Feature 1</li>
                    <li>Feature 2</li>
                  </ul>
                </div>
                <div className="text-center">
                  <button
                    className="btn btn-light rounded-pill plan-button"
                    style={{ color: "var(--lightTangerine)" }}
                  >
                    GET PLAN
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container-fluid brand-container">
        <div className="row">
          <div className="col-6 col-md-3 d-flex align-items-center justify-content-center">
            <img src={axiom} alt="Axiom Logo" className="img-fluid" />
          </div>
          <div className="col-6 col-md-3 d-flex align-items-center justify-content-center">
            <img src={kapiva} alt="Kapiva Logo" className="img-fluid" />
          </div>
          <div className="col-6 col-md-3 d-flex align-items-center justify-content-center">
            <img src={khadi} alt="Khadi Natural Logo" className="img-fluid" />
          </div>
          <div className="col-6 col-md-3 d-flex align-items-center justify-content-center">
            <img src={mantra} alt="Mantra Logo" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="container" style={{ marginBottom: "70px" }}>
        <div className="row">
          <div className="col-lg-6 mb-5">
            <div>
              <h4 style={{ color: "var(--orange)" }}>
                <b>{blogsList[0]?.title}</b>
              </h4>
              <p className="my-5">{blogsList[0]?.meta_content}</p>
              <b>
                <a
                  href={`/blogs/${blogsList[0]?.slug}`}
                  style={{ textDecoration: "none", color: "var(--orange)" }}
                >
                  Read More{" "}
                  <i
                    className="bi bi-arrow-right-square-fill ms-2"
                    style={{ fontSize: "22px" }}
                  />
                </a>
              </b>
            </div>
          </div>
          <div className="col-lg-6 mb-5">
            <div>
              <div className="text-center">
                <img
                  src={`${baseURL}/uploads/blogs/${blogsList[0]?.cover_image}`}
                  alt="img1"
                  className="img-fluid img1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-container container-fluid text-white">
        <div className="row px-4 pt-5">
          <div className="col-lg-6 mb-5 blog-col px-4">
            <div className="row h-100">
              <div className="col-lg-8 d-flex flex-column justify-content-between">
                <div>
                  <h5>
                    <b>{blogsList[1]?.title}</b>
                  </h5>
                  <p className="mt-3">{blogsList[1]?.meta_content}</p>
                </div>
                <div>
                  <a
                    href={`/blogs/${blogsList[1]?.slug}`}
                    className="btn border border-2 border-light rounded-pill text-white mt-2"
                    style={{ textDecoration: "none" }}
                  >
                    Read More{" "}
                    <i
                      className="bi bi-arrow-right-circle ms-2"
                      style={{ fontSize: "18px" }}
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-4 d-flex align-items-center justify-content-center">
                <img
                  src={`${baseURL}/uploads/blogs/${blogsList[1]?.cover_image}`}
                  alt="img"
                  className="img-fluid"
                  style={{ maxHeight: "200px" }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-5 px-4 blog-col-2">
            <div className="row h-100">
              <div className="col-lg-8 d-flex flex-column justify-content-between">
                <div>
                  <h5>
                    <b>{blogsList[2]?.title}</b>
                  </h5>
                  <p className="mt-3">{blogsList[2]?.meta_content}</p>
                </div>
                <div>
                  <a
                    href={`/blogs/${blogsList[2]?.slug}`}
                    className="btn border border-2 border-light rounded-pill text-white mt-2"
                    style={{ textDecoration: "none" }}
                  >
                    Read More{" "}
                    <i
                      className="bi bi-arrow-right-circle ms-2"
                      style={{ fontSize: "18px" }}
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-4 d-flex align-items-center justify-content-center">
                <img
                  src={`${baseURL}/uploads/blogs/${blogsList[2]?.cover_image}`}
                  alt="img"
                  className="img-fluid"
                  style={{ maxHeight: "200px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ marginBottom: "100px" }}>
        <div className="row">
          <div className="col-lg-6 d-flex align-items-center justify-content-center">
            <img src={stevia} alt="stevia plant" className="img-fluid" />
          </div>
          <div className="col-lg-6 details px-3">
            <div>
              <h3>Health Benefits of Stevia Nutrition</h3>
              <div className="px-3 mt-5">
                <div>
                  <div className="row mb-3">
                    <div className="col-1">
                      <img src={leafIcon} alt="icon" />
                    </div>
                    <span className="col ps-4">
                      <b>Good for Diabetics</b>
                    </span>
                  </div>
                  <hr />
                  <div className="row mb-3">
                    <div className="col-1">
                      <img src={leafIcon} alt="icon" />
                    </div>
                    <span className="col ps-4">
                      <b>Helps in Weight Control</b>
                    </span>
                  </div>
                  <hr />
                  <div className="row mb-3">
                    <div className="col-1">
                      <img src={leafIcon} alt="icon" />
                    </div>
                    <span className="col ps-4">
                      <b>May Reduce the Risk of Pancreatic Cancer</b>
                    </span>
                  </div>
                  <hr />
                  <div className="row mb-3">
                    <div className="col-1">
                      <img src={leafIcon} alt="icon" />
                    </div>
                    <span className="col ps-4">
                      <b>May Help to Reduce High Blood Pressure</b>
                    </span>
                  </div>
                  <hr />
                  <div className="row mb-3">
                    <div className="col-1">
                      <img src={leafIcon} alt="icon" />
                    </div>
                    <span className="col ps-4">
                      <b>Stevia Does Not Cause any Allergy</b>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
