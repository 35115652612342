/*eslint-disable react-hooks/exhaustive-deps*/

import React, { useEffect, useState } from "react";
import "./Promotion.css";
import { Link, useLocation, useParams } from "react-router-dom";
import { productsController } from "../../../api/products";
import { showNotification } from "../../../utils/showNotification";
import { cartContoller } from "../../../api/cart";
import Loader from "../../Loader/Loader";
import baseURL from "../../../utils/urls";
import { productInfo } from "./PromotionPageContent";
import parse from "react-html-parser";
import Footer from "../../Home/Footer";

function Promotion({ refresh, setRefresh }) {
  const state = useLocation().state;
  const { slug } = useParams();
  const [productDetails, setProductDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  useEffect(async () => {
    try {
      setLoading(true);
      const isLoggedIn = localStorage.getItem("meruwell_token") ? true : false;
      const result = await productsController.getProductById(
        state?.id,
        isLoggedIn,
        slug
      );
      if (result.success) {
        let products = {
          ...result.productDetails,
          product_image: result.productDetails.product_image?.split(","),
          price_with_tax:
            result.productDetails.unit_price +
            (result.productDetails.unit_price * result.productDetails.gst) /
              100,
        };
        setProductDetails(products);
      } else {
        showNotification(result.message, "error");
      }
    } catch (error) {
      showNotification(error.message, "error");
    } finally {
      setLoading(false);
    }
  }, [state?.id, refresh]);

  useEffect(() => {}, [productDetails]);

  const addToCart = async () => {
    try {
      setLoading(true);
      const result = await cartContoller.addProductToCart(productDetails?.id);
      if (result.success) {
        showNotification(result.message, "success");
      } else {
        showNotification(result.message, "error");
      }
      setRefresh(!refresh);
    } catch (error) {
      showNotification(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loader />}

      <div>
        <div className="banner-container margin-bottom">
          <img
            src={`${baseURL}/uploads/promotions/diwali/meruwell-diwali-banner.jpg`}
            alt="banner"
            className="img-fluid h-100 w-100"
            style={{ objectFit: "fill" }}
          />
        </div>

        <div className="d-flex justify-content-center text-container padding-y margin-bottom">
          <div className="text-center">
            <h1>Illuminate Diwali with the Gift of Nature!</h1>
            <hr />
            <h6>
              This festive season, gift the essence of wellness with our Natural
              Herbal Products!🎁 Embrace the goodness of nature and share the
              joy of a healthy lifestyle with your loved ones.
              <br />
              <br />
              🎉 Special Diwali Offer: Enjoy exclusive discounts on our herbal
              bundles! 🎉 <br />
              <br />
              <span className="fw-bold">
                Hurry, light up this Diwali with the power of natural goodness!
                🌟
              </span>
            </h6>
          </div>
        </div>

        <div className="d-flex justify-content-center margin-bottom">
          <div className="detail text-center">
            <div className="row ">
              <div className="col-12 col-lg-6 mb-2">
                <div className="row">
                  <div className="col-md-3 order-2 order-md-1 d-flex align-items-center justify-content-center mb-5">
                    <div>
                      <div className="d-flex flex-grow flex-md-column gap-3">
                        {productDetails?.product_image?.map((image, index) => {
                          return (
                            <div
                              className="d-flex justify-content-center"
                              onClick={() => handleSelect(index)}
                              key={index}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={`${baseURL}/uploads/${image}`}
                                className="d-block"
                                alt={productDetails?.product_name}
                                width={75}
                                height={75}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9 order-1 order-md-2 mb-5">
                    <div>
                      <div className="d-flex align-items-center justify-content-center">
                        <img
                          src={`${baseURL}/uploads/${productDetails?.product_image[activeIndex]}`}
                          className="d-block"
                          alt={productDetails?.product_name}
                          style={{
                            objectFit: "contain",
                            minWidth: "200px",
                            maxHeight: "600px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 mb-5">
                <div className="text-center mt-3 mb-5">
                  <h3 style={{ color: "gray", fontWeight: "700" }}>
                    {productDetails?.product_brand}
                  </h3>
                  <h4
                    className="text-muted mb-3"
                    style={{ fontWeight: "normal" }}
                  >
                    {productDetails?.product_name}{" "}
                    {productDetails?.product_weight
                      ? `(${productDetails?.product_weight} ${productDetails?.weight_unit})`
                      : ""}
                  </h4>
                  <h6 className="text-muted mb-4">{productDetails?.caption}</h6>
                  {productDetails?.total_quantity -
                    productDetails?.sold_quantity ===
                    0 && <h5 className="text-danger">CURRENTLY UNAVAILABLE</h5>}
                  <h2 className="mt-4">
                    ₹
                    {Math.round(
                      productDetails?.price_with_tax -
                        (productDetails?.price_with_tax *
                          productDetails?.discount) /
                          100
                    ).toFixed(2)}
                  </h2>
                  {productDetails?.discount ? (
                    <h6
                      className="text-muted mt-3"
                      style={{ fontWeight: "normal" }}
                    >
                      MRP: <s>₹{productDetails?.price_with_tax.toFixed(2)}</s>{" "}
                      <b>({productDetails?.discount}% OFF)</b>
                    </h6>
                  ) : (
                    <></>
                  )}
                  <p className="text-muted" style={{ fontSize: "12px" }}>
                    Price inclusive of all taxes
                  </p>
                </div>

                <Link to="https://forms.gle/vSjefoQ5ABELhBMYA" target="_blank">
                  <button className="btn btn-warning w-50 order-btn">
                    <div className="d-flex gap-2 align-items-center justify-content-center">
                      <i
                        className="bi bi-cart-plus-fill"
                        style={{ fontSize: "20px" }}
                      ></i>
                      <span>Click Here to Place Order</span>
                    </div>
                  </button>
                </Link>

                {/* {productDetails?.cart_entry ? (
                  <button className="btn btn-warning btn-container" disabled>
                    <i className="fas fa-shopping-cart me-3"></i>
                    ADDED TO CART
                  </button>
                ) : (
                  <button
                    className="btn btn-warning btn-container"
                    onClick={() => addToCart()}
                    disabled={
                      productDetails?.total_quantity -
                        productDetails?.sold_quantity <=
                      0
                    }
                  >
                    <i className="fas fa-shopping-cart me-3"></i>
                    ADD TO CART
                  </button>
                )} */}
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center padding-y margin-bottom">
          <div className="product-wrapper text-center">
            <h1 className="mb-5">What's in the Box?</h1>
            <div className="row">
              {productInfo.map((product) => {
                return (
                  <div
                    className="col-6 col-md-2 d-flex justify-content-center align-items-center mb-3"
                    key={product.name}
                  >
                    <div className="promotion-product-container">
                      <img
                        src={`${baseURL}/uploads/promotions/${product.preview}`}
                        className="img-fluid product-image"
                        alt={product.name}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center padding-y">
          <div className="detail">
            {productInfo.map((product, index) => {
              return (
                <div key={product.name}>
                  <div className="row mb-5 detail-wrapper">
                    <div
                      className={`col-12 col-lg-4 order-1 order-lg-${
                        index % 2 === 0 ? "1" : "2"
                      } d-flex align-items-center justify-content-center`}
                    >
                      <div className="image-container">
                        <img
                          src={`${baseURL}/uploads/promotions/${product.image}`}
                          className="img-fluid"
                          alt={product.name}
                          style={{ borderRadius: "8px" }}
                        />
                      </div>
                    </div>
                    <div
                      className={`col-12 col-lg-8 order-2 order-lg-${
                        index % 2 === 0 ? "2" : "1"
                      } d-flex align-items-center p-4`}
                    >
                      <div>
                        <h6 className="fw-bold mb-4">{product.name}</h6>
                        <span className="detail-text">
                          {parse(product.benefits)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* <div className="d-flex justify-content-center gap-3 margin-bottom">
          <button className="btn btn-outline-secondary btn-lg">Contact Us</button>
          <button className="btn btn-warning btn-lg">Order Now</button>
        </div> */}
      </div>

      <Footer />
    </div>
  );
}

export default Promotion;
