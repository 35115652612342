import { httpRequest } from "./httpRequest";
import baseURL from "../utils/urls";

const getAllProducts = async ({ page, limit, fieldsToSearch, applyLimit }) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: "/products",
      body: { page, limit, fieldsToSearch, applyLimit },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const getProductById = async (product_id, is_logged, slug) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: `/products/getProduct`,
      body: { product_id, is_logged, slug },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const productsController = { getAllProducts, getProductById };
