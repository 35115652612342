import React from "react";
import { Button, Form } from "react-bootstrap";
import Footer from "../Home/Footer";
import Header from "../Home/Header";
import classes from "./ContactUs.module.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { homeController } from "../../api/home";
import { showNotification } from "../../utils/showNotification";

function ContactUs({ setLoginShow }) {
  const schema = yup.object().shape({
    fullName: yup.string().required("Please fill the Full Name"),
    email: yup.string().required("Please fill the Email").email(),
    mobile: yup
      .string()
      .required("Please fill the Mobile Number")
      .matches(/^[6-9]\d{9}$/, "Please enter valid number"),
    message: yup.string().required("Please fill the Message"),
  });

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      mobile: "",
      message: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const res = await homeController.saveEnquiry(values);
      if (res.success) {
        formik.resetForm();
        showNotification(res.message, "success");
      }
    },
  });
  return (
    <div>
      <Header setLoginShow={setLoginShow} />
      <div className="container my-5">
        <h2 className="text-center fw-bold">Contact Us</h2>
        <p className="text-center text-muted mt-4">
          Any questions or remarks? Just write us a message here!
        </p>
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "60px", marginBottom: "60px" }}
        >
          <div style={{ width: "85%" }}>
            <div className="row">
              <div
                className={
                  "col-lg-6 d-flex align-items-center justify-content-md-center mb-5 " +
                  classes.contactInfo
                }
              >
                <div className={classes.infoContainer}>
                  <div className={classes.infoCover}>
                    <div className="mb-2 d-flex">
                      <i className="bi bi-geo-alt-fill me-3" />
                      <div>
                        <b>MeruWell Health</b>
                        <br />
                        <li className="mb-2">
                          277-B1, Thadagam Main Road, Venkitapuram, Coimbatore,
                          TamilNadu - 641025
                          <p className="d-flex">
                            <i className="bi bi-telephone-fill me-3" />
                            <span>+91 94424 06780</span>
                          </p>
                        </li>
                        <li>
                          AECS Layout, Block A, Singasandra, Bangalore,
                          Karnataka - 560068
                          <p className="d-flex">
                            <i className="bi bi-telephone-fill me-3" />
                            <span>+91 98458 45616</span>
                          </p>
                        </li>
                      </div>
                    </div>
                    <p className="mb-2 d-flex">
                      <i className="bi bi-envelope-fill me-3" />
                      <span>hello@meruwell.com</span>
                    </p>
                    <div
                      className={"text-center " + classes.socialMediaContainer}
                    >
                      <a
                        href="https://www.facebook.com/profile.php?id=100084289731907"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bi bi-facebook me-3" />
                      </a>
                      <a href="/">
                        <i className="bi bi-twitter me-3" />
                      </a>
                      <a
                        href="https://www.instagram.com/meruwellhealth"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bi bi-instagram me-3" />
                      </a>
                      <a href="/">
                        <i className="bi bi-linkedin me-3" />
                      </a>
                      <a
                        href="https://www.youtube.com/@meruwell1893"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className="bi bi-youtube me-3"
                          style={{ fontSize: "16px" }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-5">
                <h5 className="mb-5">
                  Fill the form and our Team will get back to you shortly!
                </h5>
                <Form
                  className={classes.formText}
                  onSubmit={formik.handleSubmit}
                >
                  <Form.Group className="mb-3" controlId="Name">
                    <Form.Control
                      type="text"
                      className={classes.formInput}
                      placeholder="Full Name"
                      name="fullName"
                      value={formik.values.fullName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.fullName && formik.errors.fullName && (
                      <p className="mt-1 mb-0 text-danger">
                        {formik.errors.fullName}
                      </p>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="Email">
                    <Form.Control
                      type="text"
                      className={classes.formInput}
                      placeholder="Email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <p className="mt-1 mb-0 text-danger">
                        {formik.errors.email}
                      </p>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="Mobile">
                    <Form.Control
                      type="tel"
                      className={classes.formInput}
                      placeholder="Mobile Number"
                      name="mobile"
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.mobile && formik.errors.mobile && (
                      <p className="mt-1 mb-0 text-danger">
                        {formik.errors.mobile}
                      </p>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="message">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className={classes.formInput}
                      placeholder="Type your message here..."
                      style={{ resize: "none" }}
                      name="message"
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.message && formik.errors.message && (
                      <p className="mt-1 mb-0 text-danger">
                        {formik.errors.message}
                      </p>
                    )}
                  </Form.Group>
                  <Button
                    type="submit"
                    // onClick={otpInputView ? () => {} : handleSignIn}
                    className={"px-4 " + classes.btnClass}
                  >
                    Send message
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
