import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { blogsController } from "../../api/blogs";
import { showNotification } from "../../utils/showNotification";
import baseURL from "../../utils/urls";
import Footer from "../Home/Footer";
import Header from "../Home/Header";
import Loader from "../Loader/Loader";

function Blogs({ setLoginShow }) {
  const history = useNavigate();
  const [blogsList, setBlogsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllBlogs = async () => {
    try {
      setLoading(true);
      const res = await blogsController.getAllBlogs();
      if (res.success) {
        setBlogsList(res.blogsList);
      } else {
        showNotification(res.message);
      }
    } catch (error) {
      showNotification(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  return (
    <div>
      <Header setLoginShow={setLoginShow} />
      {loading && <Loader />}
      <div className="container my-5">
        <h2 className="text-center fw-bold">Blogs</h2>
        <div style={{ marginTop: "60px", marginBottom: "60px" }}>
          <div className="row mb-4">
            {blogsList.map((blog) => {
              return (
                <div className="col-md-6 col-lg-4 mb-5" key={blog.id}>
                  <div
                    className="card"
                    onClick={() => history(`/blogs/${blog.slug}`)}
                    style={{ height: "100%", cursor: "pointer" }}
                  >
                    <div
                      style={{
                        width: "inherit",
                        height: "200px",
                      }}
                    >
                      <img
                        src={
                          blog.cover_image
                            ? `${baseURL}/uploads/blogs/${blog.cover_image}`
                            : ""
                        }
                        alt={blog.title}
                        className="card-img-top img-fluid"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="card-body">
                      <h5 className="mt-3" style={{ fontWeight: "600" }}>
                        {blog.title}
                      </h5>
                      <hr
                        className="w-50 mx-0 bg-dark"
                        style={{ height: "2px" }}
                      />
                      <p className="text-muted">{blog.meta_content}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blogs;
