/* eslint-disable react-hooks/exhaustive-deps */

import { Container, Nav, Navbar } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import doctorIcon from "../../assets/icons/doctor-icon.svg";
import { decodeToken } from "react-jwt";
import "./HomePage.css";
import Loader from "../Loader/Loader";
import { showNotification } from "../../utils/showNotification";

function Header({ setLoginShow, refresh, setRefresh }) {
  const [jwtToken, setJwtToken] = useState(
    localStorage.getItem("meruwell_token")
  );
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);

  const logout = () => {
    setLoading(true);
    setTimeout(() => {
      setJwtToken(null);
      localStorage.removeItem("meruwell_token");
      setLoading(false);
      showNotification("Signed Out Successfully", "success");
    }, 500);
  };

  useEffect(() => {
    if (jwtToken) {
      const decodedToken = decodeToken(jwtToken);
      if (decodedToken?.isAdmin) {
        localStorage.removeItem("meruwell_token");
        setJwtToken(null);
      } else {
        if (Date.now() >= decodedToken?.exp) {
          localStorage.removeItem("meruwell_token");
          setJwtToken(null);
        } else {
          setUserName(decodedToken.name || "");
        }
      }
    }
  }, [jwtToken]);

  useEffect(() => {
    setJwtToken(localStorage.getItem("meruwell_token"));
  }, [refresh]);

  return (
    <div style={{ position: "sticky", top: "0", zIndex: "999" }}>
      {loading && <Loader />}
      <Navbar
        expand="lg"
        className="py-3"
        style={{ backgroundColor: "var(--halfWhite)" }}
      >
        <Container>
          <Navbar.Brand href="/" className="mb-1">
            <img
              src="/meruwell-site-logo.png"
              width="35"
              height="35"
              className="d-inline-block align-top me-2"
              alt="MeruWell logo"
            />
            <h5 className="d-inline-block align-bottom mb-0 fw-bold">
              MeruWell
            </h5>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="mb-1" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end mb-1"
            style={{ flexGrow: "initial" }}
          >
            <Nav className="text-end" style={{ fontSize: "var(--fontSm)" }}>
              <Nav.Link href="/about-us">
                <i className="bi bi-house-fill me-1" />
                About Us
              </Nav.Link>
              <Nav.Link href="/products" target="_blank">
                <i
                  className="bi bi-bag-fill me-1"
                  style={{ fontSize: "12px" }}
                />
                Shop
              </Nav.Link>
              <Nav.Link href="/blogs">
                <i className="bi bi-journals me-1" />
                Blogs
              </Nav.Link>
              <Nav.Link href="/doctors">
                <img
                  src={doctorIcon}
                  className="me-1"
                  width={19}
                  height={16}
                  alt="doctor icon"
                />
                Doctors
              </Nav.Link>
              <Nav.Link href="/contact-us">
                <i className="bi bi-envelope-check me-1" />
                Contact
              </Nav.Link>
              <Navbar.Text style={{ cursor: "pointer" }}>
                {!jwtToken ? (
                  <div onClick={() => setLoginShow(true)}>
                    <i
                      className="bi bi-power me-1"
                      style={{ fontSize: "14px" }}
                    />
                    Sign In
                  </div>
                ) : (
                  <div className="d-flex justify-content-end gap-1">
                    <span className="text-danger" onClick={logout}>
                      Sign Out
                    </span>
                    <div
                      className="border border-secondary border-2 rounded-circle d-flex justify-content-center align-items-center"
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      <span className="fw-bold">
                        {userName.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  </div>
                )}
              </Navbar.Text>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
