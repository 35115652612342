/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Select from "react-select";

import style from "./DataTable.module.css";
import { getPageNos, optionsFormatter } from "../../../utils/helpers";
import { useLocation, useNavigate } from "react-router-dom";

const DataTable = ({ data, limitOptions }) => {
  const PAGE_WINDOW_SIZE = 5;
  const formattedOptions = optionsFormatter(limitOptions);
  const hasSearch = data?.columns?.some((col) => col.search);

  const history = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const limit = query.get("limit") ? parseInt(query.get("limit")) : 10;
  const page = query.get("page") ? parseInt(query.get("page")) : 1;
  const pageStartIndex = page <= PAGE_WINDOW_SIZE ? 0 : page - PAGE_WINDOW_SIZE;
  const offset = page * limit - limit;

  const [pagesList, setPagesList] = useState(
    getPageNos(data?.totalCount, limit)
  );
  const [searchInput, setSearchInput] = useState(
    query.get("search") ? JSON.parse(query.get("search")) : {}
  );

  const handleLimitChange = (e) => {
    const val = e ? e.value : limit;
    setPagesList(getPageNos(data?.totalCount, val));
    handlPageChange(1, val);
  };

  const checkPageActions = (type) => {
    if (type === "prev") {
      return page <= 1;
    } else if (type === "next") {
      return page >= pagesList.length;
    }
  };

  const handlePrev = () => {
    if (!checkPageActions("prev")) {
      handlPageChange(page - 1, limit);
    }
  };

  const handleNext = () => {
    if (!checkPageActions("next")) {
      handlPageChange(page + 1, limit);
    }
  };

  const handlPageChange = (page, limit) => {
    query.set("page", page);
    query.set("limit", limit);
    history({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  const handleSearchInput = () => {
    query.set("page", 1);
    query.set("limit", limit);

    // Search with value not equal to empty. will push only those to the router for fetching
    const validSearchInputs = {};
    Object.entries(searchInput)?.forEach(([k, v]) => {
      if (v) {
        validSearchInputs[k] = v;
      }
    });

    if (Object.keys(validSearchInputs).length) {
      query.set("search", JSON.stringify(validSearchInputs));
    } else {
      query.delete("search");
    }

    history({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  return (
    <div>
      <table className={style.table}>
        <thead>
          <tr>
            {data.columns?.map((col) => (
              <th key={col.field}>
                <p
                  className={`${style.heading} ${
                    hasSearch ? style.borderBottom : ""
                  }`}
                >
                  {col.label}
                </p>
                {hasSearch ? (
                  <div className={style.searchBox}>
                    {col.search ? (
                      <input
                        type="text"
                        className="form-control text-box"
                        placeholder="Search"
                        value={searchInput[col.field] || ""}
                        onChange={(e) => {
                          setSearchInput((prev) => ({
                            ...prev,
                            [col.field]: e.target.value || "",
                          }));
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSearchInput();
                          }
                        }}
                        autoComplete="off"
                      />
                    ) : null}
                  </div>
                ) : null}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.rows?.map((row, ind) => (
            <tr key={`row-${ind}`}>
              {data.columns?.map((col, i) => (
                <td
                  className={style.data}
                  style={col.style ? col.style : {}}
                  key={`${col.field}-${i}`}
                >
                  {row[col.field]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {data?.rows ? (
        <div className={style.footer}>
          <Select
            options={formattedOptions}
            value={formattedOptions.filter((opt) => opt.value == limit)}
            id="limitOptions"
            onChange={handleLimitChange}
            menuPlacement="top"
          />
          <div className={style.paginator}>
            <span
              onClick={handlePrev}
              className={`${checkPageActions("prev") ? style.disabled : ""}`}
            >
              <i className="bi bi-chevron-left" />
            </span>
            <div className={style.pages}>
              {pagesList.length
                ? pagesList
                    .slice(pageStartIndex, pageStartIndex + PAGE_WINDOW_SIZE)
                    .map((pg) => (
                      <div
                        key={`page-${pg}`}
                        className={`${style.page} ${
                          pg + 1 == page ? style.active : ""
                        }`}
                      >
                        <span onClick={() => handlPageChange(pg + 1, limit)}>
                          {pg + 1}
                        </span>
                      </div>
                    ))
                : null}
            </div>
            <span
              onClick={handleNext}
              className={`${checkPageActions("next") ? style.disabled : ""}`}
            >
              <i className="bi bi-chevron-right" />
            </span>
          </div>
          <div className={style.paginationText}>{`Showing ${offset + 1} - ${
            offset + limit
          } of ${data?.totalCount || 0} items`}</div>
        </div>
      ) : null}
    </div>
  );
};

export default DataTable;
