/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */

import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { showNotification } from "../../../../utils/showNotification";
import Loader from "../../../Loader/Loader";
import Select from "react-select";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import "./AddDoctor.css";
import { specializationController } from "../../../../api/admin/specialization";
import { adminDoctorsController } from "../../../../api/admin/doctors";
import ReactQuill from "react-quill-new";

function AddDoctor({ mode, data, refresh }) {
  const initialValue = {
    choice:
      mode === "edit" ? (data?.doctor_name ? "doctor" : "clinic") : "doctor",
    salutation: data?.salutation || "",
    doctorName: data?.doctor_name || "",
    clinicName: data?.clinic_name || "",
    address: data?.clinic_address || "",
    city: data?.city || "",
    state: data?.state || "",
    pincode: data?.pincode || "",
    phone: data?.phone_number || "",
    specialization: data?.specialization?.length
      ? data.specialization.split(",")
      : [],
    experience: data?.experience || "",
    details: data?.details || "",
    profileImg: null,
    dbImage: data?.profile || "",
  };

  const history = useNavigate();
  const editorRef = useRef(null);

  const formats = [
    "header",
    "bold",
    "italic",
    "font",
    "underline",
    "strike",
    "blockquote",
    "align",
    "list",
    "bullet",
    "link",
    "size",
    "clean",
  ];

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote"],

      [{ list: "ordered" }, { list: "bullet" }],

      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ size: ["small", "Normal", "large", "huge"] }],

      [{ font: [] }],
      [{ align: [] }],

      ["link"],
      ["clean"],
    ],
  };

  const salutationOptions = [
    { value: "Dr", label: "Dr" },
    { value: "Mr", label: "Mr" },
    { value: "Ms", label: "Ms" },
    { value: "Mrs", label: "Mrs" },
  ];

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/webp",
  ];

  const errorMessage = "*This field is required";

  const blogSchema = yup.object({
    salutation: yup
      .string()
      .trim()
      .when("choice", (choice, schema) => {
        return choice === "doctor" ? schema.required(errorMessage) : schema;
      }),
    doctorName: yup
      .string()
      .trim()
      .when("choice", (choice, schema) => {
        return choice === "doctor" ? schema.required(errorMessage) : schema;
      }),
    clinicName: yup.string().trim().required(errorMessage),
    address: yup.string().trim().required(errorMessage),
    city: yup.string().trim().required(errorMessage),
    state: yup.string().trim().required(errorMessage),
    pincode: yup
      .string()
      .trim()
      .matches(/^[1-9][0-9]{5}$/, "Please enter valid pincode"),
    phone: yup
      .string()
      .trim()
      .matches(
        /^[\d]{2,4}[- ]?[\d]{3}[- ]?[\d]{3,5}|([0])?(\+\d{1,2}[- ]?)?[6-9]{1}\d{9}$/,
        "Please enter valid number"
      ),
    experience: yup
      .string()
      .trim()
      .matches(/^\d+$/, "Please type only numbers"),
    specialization: yup.array().nullable().min(1, errorMessage),
    details: yup
      .string()
      .test("has text", errorMessage, () => {
        return editorRef.current?.unprivilegedEditor.getLength() > 1;
      })
      .test("max limit", "Maximum Characters should be 500.", () => {
        return editorRef.current?.unprivilegedEditor.getLength() < 500;
      }),
    profileImg: yup
      .mixed()
      .nullable()
      .test("format", "File Format not supported", (value) => {
        return !value || (value && SUPPORTED_FORMATS.includes(value.type));
      })
      .when("dbImage", (dbImage, schema) => {
        return dbImage ? schema : schema.required(errorMessage);
      }),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: blogSchema,
    onSubmit: async (values) => {
      try {
        values.specialization = values.specialization.length
          ? values.specialization.join(",")
          : "";

        let formData = new FormData();
        Object.keys(values)?.map((val) => {
          formData.append(val, values[val]);
        });

        if (mode === "edit") {
          let res;
          if (values.profileImg) {
            formData.append("id", data.id);
            res = await adminDoctorsController.editDoctor(formData, 1);
          } else {
            res = await adminDoctorsController.editDoctor({
              ...values,
              id: data.id,
            });
          }
          if (res.success) {
            showNotification(res.message, "success");
            refresh();
          } else {
            showNotification(res.message, "error");
          }
        } else {
          const res = await adminDoctorsController.addDoctor(formData);
          if (res.success) {
            showNotification(res.message, "success");
            formik.resetForm();
          } else {
            formik.setFieldValue("specialization", []);
            showNotification(res.message, "error");
          }
        }
      } catch (error) {
        showNotification(error.message, "error");
      } finally {
        setLoading(false);
      }
    },
    enableReinitialize: true,
  });

  const getSpecializationOptions = async () => {
    try {
      setLoading(true);
      const res = await specializationController.getAllSpecialization({
        dropdown: true,
      });
      if (res.success) {
        setSpecializationOptions(res.data);
      } else {
        showNotification(res.message, "error");
      }
    } catch (err) {
      showNotification(err.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSpecializationOptions();
  }, []);

  const fileInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const [specializationOptions, setSpecializationOptions] = useState([]);

  return (
    <div className="container mt-5">
      {loading && <Loader />}
      {!loading && (
        <div className="tab-header">
          <div className="d-flex justify-content-between">
            <h2>{mode !== "edit" ? "ADD" : "EDIT"} DOCTOR</h2>
            <div>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => history("/admin/doctors")}
              >
                <i className="bi bi-arrow-left me-1" />
                Back
              </button>
            </div>
          </div>
          <div className="product-form mt-5">
            <form onSubmit={formik.handleSubmit}>
              {mode !== "edit" && (
                <div className="row mb-5">
                  <div className="d-flex gap-4">
                    <span>Add New</span>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="add"
                        id="doctor"
                        checked={formik.values.choice === "doctor"}
                        onChange={(e) => {
                          formik.setFieldValue("choice", e.target.id);
                        }}
                      />
                      <label className="form-check-label" htmlFor="doctor">
                        Doctor
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="add"
                        id="clinic"
                        checked={formik.values.choice === "clinic"}
                        onChange={(e) => {
                          formik.setFieldValue("choice", e.target.id);
                        }}
                      />
                      <label className="form-check-label" htmlFor="clinic">
                        Clinic
                      </label>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                {formik.values.choice === "doctor" && (
                  <div className="col-md-7 row">
                    <div className="col-md-4 mb-4">
                      <label htmlFor="salutation" className="form-label">
                        Salutation*
                      </label>
                      <Select
                        options={salutationOptions}
                        styles={{
                          // Fixes the overlapping problem of the component
                          menu: (provided) => ({ ...provided, zIndex: 999 }),
                        }}
                        value={salutationOptions.filter(
                          (opt) => opt.value === formik.values.salutation
                        )}
                        name="salutation"
                        placeholder=""
                        onChange={(e) => {
                          formik.setFieldValue("salutation", e.value);
                        }}
                      />
                      {formik.touched.salutation && formik.errors.salutation ? (
                        <p className="mt-1 mb-0 text-danger">
                          {formik.errors.salutation}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-md-8 mb-4">
                      <label htmlFor="doctorName" className="form-label">
                        Doctor Name*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="doctorName"
                        value={formik.values.doctorName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                      {formik.touched.doctorName && formik.errors.doctorName ? (
                        <p className="mt-1 mb-0 text-danger">
                          {formik.errors.doctorName}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-md-5 col-lg-4 mb-4">
                  <label htmlFor="clinicName" className="form-label">
                    Clinic Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="clinicName"
                    value={formik.values.clinicName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.touched.clinicName && formik.errors.clinicName ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.clinicName}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mb-4">
                  <label className="form-label">Clinic Address*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                    placeholder="House No/Area/Street"
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.address}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-md-4 col-lg-3 mb-4">
                  <label className="form-label">City*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.city}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-md-4 col-lg-3 mb-4">
                  <label className="form-label">State & Country*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.touched.state && formik.errors.state ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.state}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-xl-3 mb-4">
                  <label className="form-label">Pincode</label>
                  <input
                    type="text"
                    className="form-control"
                    name="pincode"
                    value={formik.values.pincode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.touched.pincode && formik.errors.pincode ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.pincode}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-md-4 mb-4">
                  <label htmlFor="phone" className="form-label">
                    Contact Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.phone}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-md-4 col-lg-3 mb-4">
                  <label htmlFor="experience" className="form-label">
                    Experience (in Yrs)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="experience"
                    value={formik.values.experience}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.touched.experience && formik.errors.experience ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.experience}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-10 col-lg-7 mb-4">
                  <label htmlFor="specialization" className="form-label">
                    Specialization*
                  </label>
                  <Select
                    isMulti
                    isClearable
                    options={specializationOptions}
                    name="specialization"
                    value={
                      formik.values.specialization?.length
                        ? specializationOptions.filter((opt) =>
                            formik.values.specialization.includes(
                              opt.value.toString()
                            )
                          )
                        : []
                    }
                    onChange={(e) => {
                      const ids = e.map((d) => d.value.toString());
                      formik.setFieldValue("specialization", ids);
                    }}
                    placeholder=""
                  />
                  {formik.touched.specialization &&
                  formik.errors.specialization ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.specialization}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="row">
                <div className={"col-md-12 col-lg-10 mb-4 quillContainer"}>
                  <label className="form-label">About*</label>
                  <ReactQuill
                    theme="snow"
                    ref={editorRef}
                    modules={modules}
                    formats={formats}
                    value={formik.values.details}
                    onChange={(val) => {
                      formik.setFieldValue("details", val);
                    }}
                    onBlur={() => formik.setFieldTouched("details", true)}
                  />
                  {formik.touched.details && formik.errors.details ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.details}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 col-lg-6 mb-4">
                  <label htmlFor="profileImg" className="form-label">
                    Cover Image*
                  </label>
                  <input
                    accept=".jpg,.jpeg,.png,.webp"
                    className="form-control"
                    type="file"
                    name="profileImg"
                    ref={fileInput}
                    onChange={(e) => {
                      formik.setFieldValue("dbImage", "");
                      formik.setFieldTouched("profileImg", true);
                      formik.setFieldValue(
                        "profileImg",
                        e.target.files.length ? e.target.files[0] : null
                      );
                    }}
                  />
                  {formik.touched.profileImg && formik.errors.profileImg ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.profileImg}
                    </p>
                  ) : (
                    <></>
                  )}
                  {mode === "edit" && formik.values.dbImage && (
                    <p className="mt-3 mb-0">{formik.values.dbImage}</p>
                  )}
                </div>
              </div>
              <button type="submit" className="btn btn-primary my-4">
                {mode === "edit" ? "Update" : "Add"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddDoctor;
