/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { showNotification } from "../../../../utils/showNotification";
import Loader from "../../../Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { adminBlogsController } from "../../../../api/admin/blogs";
import DataTable from "../../../Common/DataTable/DataTable";

function ListBlogs() {
  const history = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState({
    columns: [
      {
        label: "Blog Id",
        field: "id",
      },
      {
        label: "Title",
        field: "title",
        style: {
          textAlign: "left",
        },
      },
      {
        label: "Published On",
        field: "created_at",
      },
      {
        label: "Status",
        field: "status",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: [],
  });

  const navigateTo = (page, id) => {
    if (page === "add") {
      history("/admin/blogs/add");
    } else if (page === "edit") {
      history(`/admin/blogs/${id}/edit`);
    }
  };

  const fetchBlogs = async () => {
    try {
      setLoading(true);
      const result = await adminBlogsController.getAllBlogs({
        page: query.get("page") || 1,
        limit: query.get("limit") || 10,
      });
      if (result.success) {
        setBlogs({
          ...blogs,
          totalCount: result.blogCount,
          rows: result.blogsList.map((blog) => {
            return {
              ...blog,
              created_at: new Date(blog.created_at).toLocaleDateString("en-GB"),
              action: (
                <>
                  <span
                    className="me-3"
                    onClick={() => {
                      navigateTo("edit", blog.id);
                    }}
                  >
                    <i className="icons bi bi-pencil-square"></i>
                  </span>
                </>
              ),
            };
          }),
        });
      }
    } catch (error) {
      showNotification(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, [location]);

  return (
    <div className="container mt-5">
      {loading && <Loader />}
      <h2>BLOGS LIST</h2>
      <div className="mt-3 d-flex justify-content-end">
        <button className="btn btn-primary" onClick={() => navigateTo("add")}>
          Add Blog
        </button>
      </div>
      <div className="mt-4">
        {!loading && (
          <DataTable
            data={blogs}
            limitOptions={[10, 20, 40, 50]}
            noRecordsFoundLabel={"No Products Found"}
            sortable={false}
          />
        )}
      </div>
    </div>
  );
}

export default ListBlogs;
