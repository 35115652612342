/*eslint-disable react-hooks/exhaustive-deps*/

import React, { useEffect, useState } from "react";
import { cartContoller } from "../../../api/cart";
import { showNotification } from "../../../utils/showNotification";
import Loader from "../../Loader/Loader";
import AddressModal from "./AddressModal";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { loginController } from "../../../api/login";
import { decodeToken } from "react-jwt";

function PlanDetails() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const history = useNavigate();
  const location = useLocation();

  const initialValue = {
    name: "",
    mobile_number: "",
    email: "",
    address: "",
  };

  const errorMessage = "*This field is required";

  const schema = yup.object({
    name: yup.string().trim().required(errorMessage),
    mobile_number: yup
      .string()
      .trim()
      .required(errorMessage)
      .matches(/^[0]?[6789]\d{9}$/, "Please enter valid number"),
    email: yup.string().required(errorMessage).email(),
    address: yup.string().required("*Please Select an Address"),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        // const res = await cartContoller.addAddress(values);
        // if (res.success) {
        //   showNotification(res.message, "success");
        //   handleClose();
        //   formik.resetForm();
        //   fetchData();
        // } else {
        //   showNotification(res.message, "error");
        // }
      } catch (err) {
        showNotification(err.message, "error");
      } finally {
        setLoading(false);
      }
    },
  });

  const handleClose = () => {
    setShow(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const decodedToken = decodeToken(localStorage.getItem("meruwell_token"));
      const userData = await loginController.getUserById(decodedToken.id);
      if (userData.success) {
        formik.setFieldValue("name", userData.data.username);
        formik.setFieldValue("mobile_number", userData.data.mobile_number);
        formik.setFieldValue("email", userData.data.email);
      }
      const res = await cartContoller.getAddress();
      if (res.success) {
        if (res.data?.length) {
          const defAddr = res.data?.find((d) => d.default_address);
          formik.setFieldValue("address", defAddr ? defAddr.id.toString() : "");
          setAddresses(res.data);
        }
      } else {
        showNotification(res.message, "error");
      }
    } catch (err) {
      showNotification(err.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state === "plan") {
      fetchData();
    } else {
      history("/404", { replace: true });
    }
  }, []);

  return (
    <div className="container cart-container">
      <h2 className="mb-3">Add Details</h2>

      {loading && <Loader />}

      <form>
        <div className="row mt-5">
          <div className="col-md-4 mb-3">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              name="name"
              autoComplete="off"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.name && formik.errors.name ? (
              <p className="mt-1 mb-0 text-danger">{formik.errors.name}</p>
            ) : (
              <></>
            )}
          </div>
          <div className="col-md-4 mb-3">
            <label htmlFor="name" className="form-label">
              Mobile Number
            </label>
            <input
              className="form-control"
              type="tel"
              autoComplete="off"
              value={formik.values.mobile_number || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onKeyDown={(e) => {
                if (e.key.length === 1) {
                  let ascii = e.key.charCodeAt();
                  if (!(ascii >= 48 && ascii <= 57)) {
                    e.preventDefault();
                  }
                }
              }}
            />
            {formik.touched.mobile_number && formik.errors.mobile_number ? (
              <p className="mt-1 mb-0 text-danger">
                {formik.errors.mobile_number}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="col-md-4 mb-3">
            <label htmlFor="name" className="form-label">
              Email
            </label>
            <input
              type="text"
              className="form-control form-control-sm mb-1"
              name="email"
              autoComplete="off"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email ? (
              <p className="mt-1 mb-0 text-danger">{formik.errors.email}</p>
            ) : (
              <></>
            )}
          </div>
        </div>

        <hr></hr>
        <div className="row">
          <div className="d-md-flex justify-content-md-between align-items-md-center mb-4">
            <label htmlFor="address" className="form-label mb-4 d-flex gap-3">
              Address
              {addresses.length &&
              formik.touched.address &&
              formik.errors.address ? (
                <p className="mb-0 text-danger">{formik.errors.address}</p>
              ) : (
                <></>
              )}
            </label>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setShow(true);
                }}
              >
                Add New Address
              </button>
            </div>
          </div>

          {!addresses.length && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "50vh" }}
            >
              <div>
                <h3>No Address been added yet!</h3>
              </div>
            </div>
          )}

          {addresses?.map((addr, idx) => {
            return (
              <div className="col-12 col-md-6 col-lg-4 mb-5" key={idx}>
                <div className="form-check h-100">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="address"
                    id={addr.id}
                    checked={addr.id.toString() === formik.values.address}
                    onChange={(e) => {
                      formik.setFieldValue("address", e.target.id);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <label className="form-check-label h-100" htmlFor={addr.id}>
                    <div
                      className="card"
                      onClick={() => {}}
                      style={{ height: "100%" }}
                    >
                      <div className="card-body">
                        <div className="card-text address-container">
                          <h6 className="mb-3">
                            <b>{addr?.name}</b>
                          </h6>
                          <p>
                            {addr?.house_no}, {addr?.street},
                          </p>
                          <p>{addr?.landmark}</p>
                          <p>
                            {addr?.city}, {addr?.state} - {addr?.pincode},
                          </p>
                          <p>{addr?.country}.</p>
                          <p>Phone number : {addr?.mobile_number}</p>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      </form>

      <div className="my-4 d-flex justify-content-end">
        <div>
          <button
            type="button"
            className="btn btn-primary mx-2"
            onClick={() => history("/", { replace: true })}
          >
            <i className="bi bi-arrow-left me-2"></i>Back
          </button>
          <button
            type="button"
            className="btn btn-primary ms-2"
            onClick={formik.handleSubmit}
          >
            Proceed to Payment
          </button>
        </div>
      </div>

      <AddressModal
        show={show}
        handleClose={handleClose}
        fetchData={fetchData}
      />
    </div>
  );
}

export default PlanDetails;
