/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { adminBlogsController } from "../../../../api/admin/blogs";
import { showNotification } from "../../../../utils/showNotification";
import Loader from "../../../Loader/Loader";
import AddBlog from "../AddBlog/AddBlog";

function EditBlog() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState();

  const getBlog = async () => {
    try {
      setLoading(true);
      const res = await adminBlogsController.getBlogById(id);
      if (res.success) {
        setEditData(res.data);
      } else {
        showNotification(res.message, "error");
      }
    } catch (err) {
      showNotification(err.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBlog();
  }, [id]);

  return (
    <>
      {loading && <Loader />}
      {!loading && <AddBlog mode={"edit"} data={editData} refresh={getBlog} />}
    </>
  );
}

export default EditBlog;
