/*eslint-disable react-hooks/exhaustive-deps*/

import React, { useEffect, useState } from "react";
import "./ProductDescription.css";
import { Nav, Tab } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import { productsController } from "../../../api/products";
import { showNotification } from "../../../utils/showNotification";
import parse from "react-html-parser";
import { cartContoller } from "../../../api/cart";
import Loader from "../../Loader/Loader";
import baseURL from "../../../utils/urls";

function ProductDescription({ refresh, setRefresh }) {
  const state = useLocation().state;
  const { slug } = useParams();
  const [productDetails, setProductDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const fetchProductById = async () => {
    try {
      setLoading(true);
      const isLoggedIn = localStorage.getItem("meruwell_token") ? true : false;
      const result = await productsController.getProductById(
        state?.id,
        isLoggedIn,
        slug
      );
      if (result.success) {
        let products = {
          ...result.productDetails,
          product_image: result.productDetails.product_image?.split(","),
          price_with_tax:
            result.productDetails.unit_price +
            (result.productDetails.unit_price * result.productDetails.gst) /
              100,
        };
        setProductDetails(products);
      } else {
        showNotification(result.message, "error");
      }
    } catch (error) {
      showNotification(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductById();
  }, [state?.id, refresh]);

  const addToCart = async () => {
    try {
      setLoading(true);
      const result = await cartContoller.addProductToCart(productDetails?.id);
      if (result.success) {
        showNotification(result.message, "success");
      } else {
        showNotification(result.message, "error");
      }
      setRefresh(!refresh);
    } catch (error) {
      showNotification(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container product-container">
      {loading && <Loader />}
      {!loading && productDetails ? (
        <div className="row">
          <div className="col-12 col-lg-7 mb-5">
            <div className="row">
              <div className="col-md-3 order-2 order-md-1 d-flex align-items-center justify-content-center mb-5">
                <div>
                  <div className="d-flex flex-grow flex-md-column gap-3">
                    {productDetails?.product_image?.map((image, index) => {
                      return (
                        <div
                          className="d-flex justify-content-center"
                          onClick={() => handleSelect(index)}
                          key={index}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={`${baseURL}/uploads/${image}`}
                            className="d-block"
                            alt={productDetails?.product_name}
                            width={90}
                            height={100}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-md-9 order-1 order-md-2 mb-5">
                <div>
                  <div className="d-flex align-items-center justify-content-center">
                    <img
                      src={`${baseURL}/uploads/${productDetails?.product_image[activeIndex]}`}
                      className="d-block"
                      alt={productDetails?.product_name}
                      style={{
                        objectFit: "contain",
                        minWidth: "200px",
                        maxHeight: "600px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="d-flex align-items-center justify-content-center">
              <div id="imageSlider">
                <div className="d-flex">
                  <div className="d-flex flex-column me-5">
                    <div>
                      {productDetails?.product_image?.map((image, index) => {
                        return (
                          <button
                            type="button"
                            className="p-0"
                            style={{ border: "none" }}
                            onClick={() => handleSelect(index)}
                            key={index}
                          >
                            <img
                              src={`${baseURL}/uploads/${image}`}
                              className="d-block"
                              alt={productDetails?.product_name}
                              width={100}
                              height={100}
                            />
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <div>
      
                    <div>
                      <img
                        src={`${baseURL}/uploads/${productDetails?.product_image[activeIndex]}`}
                        className="d-block mx-auto"
                        alt={productDetails?.product_name}
                        width={560}
                        height={602}
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-9 offset-lg-3 mt-3">
                <Tab.Container defaultActiveKey="returns">
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="returns"
                        style={{ cursor: "pointer" }}
                      >
                        RETURNS
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="promise"
                        style={{ cursor: "pointer" }}
                      >
                        OUR PROMISE
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="returns">
                      <p className="p-3">
                        Easy 15 days return and exchange. Return Policies may
                        vary based on products and promotions.
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="promise">
                      <p className="p-3">
                        We assure the authenticity and quality of our products
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-5 mb-5">
            <div className="text-center">
              <h3 style={{ color: "gray", fontWeight: "700" }}>
                {productDetails?.product_brand}
              </h3>
              <h4 className="text-muted mb-3" style={{ fontWeight: "normal" }}>
                {productDetails?.product_name}{" "}
                {productDetails?.product_weight
                  ? `(${productDetails?.product_weight} ${productDetails?.weight_unit})`
                  : ""}
              </h4>
              <h6 className="text-muted mb-4">{productDetails?.caption}</h6>
              {productDetails.total_quantity - productDetails.sold_quantity ===
                0 && <h5 className="text-danger">CURRENTLY UNAVAILABLE</h5>}
              <h2 className="mt-4">
                ₹
                {Math.round(
                  productDetails?.price_with_tax -
                    (productDetails?.price_with_tax *
                      productDetails?.discount) /
                      100
                ).toFixed(2)}
              </h2>
              {productDetails?.discount ? (
                <h6
                  className="text-muted mt-3"
                  style={{ fontWeight: "normal" }}
                >
                  MRP: <s>₹{productDetails?.price_with_tax.toFixed(2)}</s>{" "}
                  <b>({productDetails?.discount}% OFF)</b>
                </h6>
              ) : (
                <></>
              )}
              <p className="text-muted" style={{ fontSize: "12px" }}>
                Price inclusive of all taxes
              </p>
            </div>
            {/* <div className="text-center d-flex justify-content-center">
              <div
                className="coupon-container border row py-2"
                style={{ backgroundColor: "#A8A8A8" }}
              >
                <div
                  className="col-4 border-end"
                  style={{ wordWrap: "break-word" }}
                >
                  <p
                    className="text-wrap"
                    style={{ fontSize: "11px", marginBottom: "0px" }}
                  >
                    Use Code <br />
                    VANDEMATRAM
                  </p>
                </div>
                <div className="col d-flex align-items-center">
                  <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                    Get Flat 75% Off on 2490 and Above
                  </p>
                </div>
              </div>
            </div> */}
            <div className="mt-5 d-flex justify-content-center">
              {productDetails?.cart_entry ? (
                <button className="btn btn-warning btn-container" disabled>
                  <i className="bi bi-cart me-3"></i>
                  ADDED TO CART
                </button>
              ) : (
                <button
                  className="btn btn-warning btn-container"
                  onClick={() => addToCart()}
                  disabled={
                    productDetails.total_quantity -
                      productDetails.sold_quantity <=
                    0
                  }
                >
                  <i className="bi bi-cart me-3"></i>
                  ADD TO CART
                </button>
              )}
            </div>
            <div className="mt-5 px-5">
              <h5>Product Details</h5>
              {parse(productDetails?.product_details)}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ProductDescription;
