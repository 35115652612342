import React from "react";
import "./Loader.css";
import logo from "../../assets/meruwell-logo.webp";

function Loader() {
  return (
    <div className="d-flex justify-content-center align-items-center loader">
      <div style={{ width: "100px", height: "100px" }}>
        <svg width="90px" height="90px" viewBox="-4 -1 38 28">
          <polygon
            fill="transparent"
            stroke="#000"
            strokeWidth="2"
            points="15,0 30,30 0,30"
          ></polygon>
        </svg>
        <div className="logo-container">
          <img src={logo} alt="logo" width={"100%"} height={"100%"} />
        </div>
      </div>
    </div>
  );
}

export default Loader;
