/*eslint-disable react-hooks/exhaustive-deps*/
import { useEffect, useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { showNotification } from "../../../utils/showNotification";
import { decodeToken } from "react-jwt";
import Loader from "../../Loader/Loader";
import { cartContoller } from "../../../api/cart";
import { useLocation, useNavigate } from "react-router-dom";

function Navbartop({ loginShow, setLoginShow, refresh, setRefresh }) {
  const jwtToken = localStorage.getItem("meruwell_token");
  const history = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [loginText, setLoginText] = useState("");
  const [loading, setLoading] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [search, setSearch] = useState(query.get("search"));

  const getCartCount = async () => {
    setLoginText(jwtToken ? "Sign Out" : "Sign In");
    try {
      const res = await cartContoller.getUserCartCount();
      if (res.success) {
        setCartCount(res.cartItemsCount);
      } else {
        setCartCount(0);
      }
    } catch (error) {
      showNotification(error.message, "error");
    }
  };

  useEffect(() => {
    if (jwtToken) {
      const decodedToken = decodeToken(jwtToken);
      if (decodedToken?.isAdmin) {
        localStorage.removeItem("meruwell_token");
        setRefresh(!refresh);
      } else {
        if (Date.now() >= decodedToken?.exp) {
          forceLogout();
        }
      }
    }
  }, []);

  useEffect(() => {
    getCartCount();
  }, [loginShow, refresh, search]);

  const logout = () => {
    setLoading(true);
    setTimeout(() => {
      localStorage.removeItem("meruwell_token");
      setRefresh(!refresh);
      setLoading(false);
      showNotification("Signed Out Successfully", "success");
    }, 500);
  };

  const forceLogout = () => {
    localStorage.removeItem("meruwell_token");
    setRefresh(!refresh);
    showNotification(
      "Session Expired! Please login again to Continue Shopping",
      "error"
    );
  };

  const login = () => {
    setRefresh(!refresh);
    setLoginShow(true);
  };

  return (
    <div style={{ position: "sticky", top: "0", zIndex: "999" }}>
      {loading && <Loader />}
      <Navbar
        expand="lg"
        className="py-3"
        style={{ backgroundColor: "var(--halfWhite)" }}
      >
        <Container>
          <Navbar.Brand href="/products" className="mb-1">
            <img
              src="/meruwell-site-logo.png"
              width="35"
              height="35"
              className="d-inline-block align-top me-2"
              alt="MeruWell logo"
            />
            <h5 className="d-inline-block align-bottom mb-0 fw-bold">
              MeruWell
            </h5>
          </Navbar.Brand>
          <div className="search mb-1">
            <form>
              <div className="input-group">
                <span
                  className="input-group-text"
                  style={{ fontSize: "var(--fontSm)" }}
                >
                  {query.get("category") || "All Categories"}
                </span>
                <input
                  type="text"
                  className="form-control text-box"
                  placeholder="Search Products"
                  value={search || ""}
                  onChange={(e) => {
                    setSearch(e.target.value || "");
                  }}
                  autoComplete="off"
                />
                <button
                  className="btn btn-warning"
                  type="submit"
                  id="search"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!search) {
                      query.delete("search");
                    } else {
                      query.set("search", search);
                    }
                    history({
                      pathname: "/products",
                      search: query.toString(),
                    });
                  }}
                >
                  <i
                    className="bi bi-search"
                    style={{ fontSize: "var(--fontSm)" }}
                  ></i>
                </button>
              </div>
            </form>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="mb-1" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end mb-1"
            style={{ flexGrow: "initial" }}
          >
            <Nav className="text-end" style={{ fontSize: "var(--fontSm)" }}>
              <Nav.Link href="/products">
                <i
                  className="bi bi-house-fill me-1"
                  style={{ fontSize: "16px" }}
                ></i>
                Home
              </Nav.Link>
              <Nav.Link href="/cart">
                <i
                  className="bi bi-cart-fill me-1 position-relative"
                  style={{ fontSize: "16px" }}
                >
                  {cartCount ? (
                    <span
                      style={{ fontSize: "7px", fontWeight: "800" }}
                      className="position-absolute top-50 start-50 translate-middle badge text-white"
                    >
                      {cartCount}
                    </span>
                  ) : (
                    <></>
                  )}
                </i>
                <span className="">Cart</span>
              </Nav.Link>
              <Navbar.Text
                className="ps-2"
                style={{ cursor: "pointer" }}
                onClick={loginText === "Sign In" ? login : logout}
              >
                <i
                  className="bi bi-power me-1"
                  style={
                    loginText === "Sign In"
                      ? { fontSize: "16px" }
                      : { fontSize: "16px", color: "red" }
                  }
                ></i>
                {loginText}
              </Navbar.Text>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Navbartop;
