/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */

import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import { showNotification } from "../../../../utils/showNotification";
import Loader from "../../../Loader/Loader";
import Select from "react-select";
import * as yup from "yup";
import { adminBlogsController } from "../../../../api/admin/blogs";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill-new";
import { base64decode, base64encode } from "nodejs-base64";

function AddBlog({ mode, data, refresh }) {
  const initialValue = {
    title: data?.title || "",
    slug: data?.slug || "",
    metaContent: data?.meta_content || "",
    detailedContent: data?.detailed_content
      ? base64decode(data.detailed_content)
      : "",
    keywords: data?.keywords || "",
    coverImg: null,
    dbImage: data?.cover_image || "",
    status: data?.status || "",
  };

  const history = useNavigate();
  const editorRef = useRef(null);

  const formats = [
    "header",
    "bold",
    "italic",
    "color",
    "background",
    "font",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "script",
    "direction",
    "align",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "size",
    "clean",
  ];

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ size: ["small", "Normal", "large", "huge"] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const statusOptions = [
    { value: "Draft", label: "Draft" },
    { value: "Published", label: "Publish" },
    { value: "Closed", label: "Close" },
  ];

  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const blogSchema = yup.object({
    title: yup.string().trim().required("*This field is required"),
    metaContent: yup.string().trim().required("*This field is required"),
    keywords: yup.string().trim().required("*This field is required"),
    detailedContent: yup
      .string()
      .test("has text", "*This field is required", (val) => {
        return editorRef.current?.unprivilegedEditor.getLength() > 1;
      }),
    coverImg: yup
      .mixed()
      .nullable()
      .test("format", "File Format not supported", (value) => {
        return !value || (value && SUPPORTED_FORMATS.includes(value.type));
      })
      .when("dbImage", (dbImage, schema) => {
        return dbImage ? schema : schema.required("*This field is required");
      }),
    status: yup.string().trim().required("*This field is required"),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: blogSchema,
    onSubmit: async (values) => {
      try {
        //Encoding Detailed Content
        const encodeText = base64encode(
          values.detailedContent.replace(/<img /g, '<img class="img-fluid"')
        );
        values.detailedContent = encodeText.toString("base64");

        let formData = new FormData();
        Object.keys(values)?.map((val) => {
          formData.append(val, values[val]);
        });

        if (mode === "edit") {
          let res;
          if (values.coverImg) {
            formData.append("id", data.id);
            res = await adminBlogsController.editBlog(formData, 1);
          } else {
            res = await adminBlogsController.editBlog({
              ...values,
              id: data.id,
            });
          }
          if (res.success) {
            showNotification(res.message, "success");
            refresh();
          } else {
            showNotification(res.message, "error");
          }
        } else {
          const res = await adminBlogsController.addBlog(formData);
          if (res.success) {
            showNotification(res.message, "success");
          } else {
            showNotification(res.message, "error");
          }
        }
      } catch (error) {
        showNotification(error.message, "error");
      } finally {
        setLoading(false);
      }
    },
    enableReinitialize: true,
  });

  const fileInput = useRef(null);
  const [loading, setLoading] = useState(false);

  return (
    <div className="container mt-5">
      {loading && <Loader />}
      {!loading && (
        <div className="tab-header">
          <div className="d-flex justify-content-between">
            <h2>{mode !== "edit" ? "ADD" : "EDIT"} BLOG</h2>
            <div>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => history("/admin/blogs")}
              >
                <i className="bi bi-arrow-left me-1" />
                Back
              </button>
            </div>
          </div>
          <div className="product-form mt-5">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6 col-lg-4 mb-4">
                  <label htmlFor="title" className="form-label">
                    Blog Title*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    value={formik.values.title}
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.setFieldValue("title", value);
                      let slug = value
                        ? value?.toLowerCase()?.split(" ").join("-")
                        : "";
                      formik.setFieldValue("slug", slug);
                    }}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.title}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-md-6 col-lg-4 mb-4">
                  <label htmlFor="slug" className="form-label">
                    Blog Slug*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="slug"
                    value={formik.values.slug}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-10 mb-4">
                  <label className="form-label">Meta Content*</label>
                  <textarea
                    value={formik.values.metaContent}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="metaContent"
                    className="form-control"
                    rows={3}
                    style={{ resize: "none" }}
                  />
                  {formik.touched.metaContent && formik.errors.metaContent ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.metaContent}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-10 mb-4">
                  <label className="form-label">Content*</label>
                  <ReactQuill
                    theme="snow"
                    ref={editorRef}
                    modules={modules}
                    formats={formats}
                    value={formik.values.detailedContent}
                    onChange={(val) => {
                      formik.setFieldValue("detailedContent", val);
                    }}
                    onBlur={() =>
                      formik.setFieldTouched("detailedContent", true)
                    }
                  />
                  {formik.touched.detailedContent &&
                  formik.errors.detailedContent ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.detailedContent}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-10 mb-4">
                  <label className="form-label">Keywords*</label>
                  <textarea
                    value={formik.values.keywords}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="keywords"
                    className="form-control"
                    rows={2}
                    style={{ resize: "none" }}
                  />
                  {formik.touched.keywords && formik.errors.keywords ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.keywords}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 col-lg-6 mb-4">
                  <label htmlFor="coverImg" className="form-label">
                    Cover Image*
                  </label>
                  <input
                    accept=".jpg,.jpeg,.png,.webp"
                    className="form-control"
                    type="file"
                    name="coverImg"
                    ref={fileInput}
                    onChange={(e) => {
                      formik.setFieldValue("dbImage", "");
                      formik.setFieldTouched("coverImg", true);
                      formik.setFieldValue(
                        "coverImg",
                        e.target.files.length ? e.target.files[0] : null
                      );
                    }}
                  />
                  {formik.touched.coverImg && formik.errors.coverImg ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.coverImg}
                    </p>
                  ) : (
                    <></>
                  )}
                  {mode === "edit" && formik.values.dbImage && (
                    <p className="mt-3 mb-0">{formik.values.dbImage}</p>
                  )}
                </div>
                <div className="col-md-4 col-lg-3 mb-4">
                  <label htmlFor="status" className="form-label">
                    Status*
                  </label>
                  <Select
                    options={statusOptions}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 999 }),
                    }}
                    value={statusOptions.filter(
                      (opt) => opt.value === formik.values.status
                    )}
                    name="status"
                    onChange={(e) => {
                      formik.setFieldValue("status", e.value);
                    }}
                  />
                  {formik.touched.status && formik.errors.status ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.status}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <button type="submit" className="btn btn-primary my-4">
                {mode === "edit" ? "Update" : "Add"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddBlog;
