/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../Home/Footer";
import Header from "../../Home/Header";
import Loader from "../../Loader/Loader";
import "../Blogs.css";
import parse from "react-html-parser";
import { blogsController } from "../../../api/blogs";
import { showNotification } from "../../../utils/showNotification";
import baseURL from "../../../utils/urls";
import { base64decode } from "nodejs-base64";

function BlogDescription({ setLoginShow }) {
  const params = useParams();
  const history = useNavigate();
  const [blog, setBlog] = useState({});
  const [loading, setLoading] = useState(false);

  const getBlog = async () => {
    try {
      setLoading(true);
      const res = await blogsController.getBlogBySlug(params.slug);
      if (res.success) {
        if (Object.keys(res.blogDetails).length) {
          setBlog({
            ...res.blogDetails,
            detailed_content: base64decode(res.blogDetails.detailed_content),
          });
        } else {
          setBlog({});
          history("/404");
        }
      } else {
        showNotification(res.message, "error");
      }
    } catch (err) {
      showNotification(err.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBlog();
  }, [params]);

  return (
    <div>
      <Header setLoginShow={setLoginShow} />
      {loading && <Loader />}
      <div className="container my-5">
        <h2 className="text-center fw-bold">{blog.title}</h2>
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "60px", marginBottom: "75px" }}
        >
          <div className="blog-content text-center">
            <img
              src={
                blog.cover_image
                  ? `${baseURL}/uploads/blogs/${blog.cover_image}`
                  : ""
              }
              alt={blog.title}
              className="img-fluid"
            />
            <div
              className="blog-content-description ql-editor"
              style={{ height: "auto" }}
            >
              {parse(blog.detailed_content)}
            </div>
          </div>
        </div>
        {blog.relatedPosts?.length ? (
          <div className="d-flex align-items-center justify-content-center">
            <div className="w-75">
              <h2 className="text-center fw-bold mb-5">Related Artciles</h2>
              <div className="row mb-4">
                {blog.relatedPosts.map((post) => {
                  return (
                    <div className="col-md-6 col-lg-4 mb-5" key={post.id}>
                      <div
                        className="card"
                        onClick={() => history(`/blogs/${post.slug}`)}
                        style={{ height: "100%", cursor: "pointer" }}
                      >
                        <div
                          style={{
                            width: "inherit",
                            height: "200px",
                          }}
                        >
                          <img
                            src={
                              post.cover_image
                                ? `${baseURL}/uploads/blogs/${post.cover_image}`
                                : ""
                            }
                            alt={post.title}
                            className="card-img-top img-fluid"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="card-body d-flex align-items-center justify-content-center">
                          <h5
                            className="text-center"
                            style={{ fontWeight: "600" }}
                          >
                            {post.title}
                          </h5>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default BlogDescription;
